<template>
  <div
    style="position: relative"
    :class="customWidth ? customWidth : 'chart-container'"
  >
    <Bar
      :data="data"
      :options="chartOptions"
      :class="customWidth ? customWidth : 'chart-container'"
    />
  </div>
</template>

<style scoped>
.chart-container {
  width: 90% !important;
  height: 100% !important;
}
</style>
<script setup>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

const props = defineProps({
  chartData: Object,
  customWidth: String,
  chartOptions: Object,
});
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

const data = ref(props.chartData);
watch(props.chartData, () => {
  data.value = [];
  nextTick(() => {
    data.value = props.chartData;
  });
});
</script>
